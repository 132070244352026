var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"12b013f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.SENTRY_ENV || process.env.NODE_ENV

/**
 * Note: No need to set `release` during initialization, since it's
 * inlined during compilation by Sentry's webpack plugin
 */
Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  tracesSampleRate: 1.0,
  ignoreErrors: [/atrack is not defined/, /Illegal invocation/],
})
